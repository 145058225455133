export default Object.freeze({
	Keno8: 1,
	Keno10: 2,
	Keno8_2: 10,
	Keno10_2: 11,
	Crash: 3,
	HiLo: 4,
	Penalty: 5,
	SicBo: 6,
	Rocket: 7,
	KenoExpress: 8,
	BlackJack: 9,
	CashShow: 12,
	GoldenRA: 13,
	Mines: 14,
	Thimble: 15,
	Plinkoman: 16,
	Roulette: 17,
	RouletteX: 17,
	Crasher: 18,
	JungleWheel: 19,
	NinjaCrash: 20,
	CoinFlip: 21,
	ScratchMap: 22,
	Scratch: 22,
	Totem: 24,
	MagicDice: 25,
	BingoStar: 27,
	LottoBoom: 28,
	TurboMines: 31,
	Atlantis: 32,
	Maestro: 36,
	LimboCrash: 39,
	TowerRush: 37,
	HotGear: 46,
	DrShocker: 35,
	CosmoSaga: 43,
	StarLight: 49,
	Figoal: 48,
	HamsterMania: 50,
	SlapShot: 53
});
