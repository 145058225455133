// Global constants
export const GET_FILTERS = "GetFilters";

export const RULE = "rule";
export const GET_RULE_GUID = "GetRuleGuId";
export const SAVE_RULES = "SaveRules";
export const EDIT_RULES = "EditRule";
export const GET_RULES_VERSION_HISTORY = "GetRulesVersionHistory";
export const GET_RULE_BY_ID = "GetRuleById";
export const GET_RULES = "GetRules";
export const UPLOAD_IMAGES = "UploadImages";

export const TRANSLATION = "translation";
export const GET_TRANSLATIONS_FILTERS = "GetTranslationsFilters";
export const GET_TRANSLATIONS = "GetTranslations";
export const EXPORT_TRANSLATIONS = "ExportTranslations";
export const IMPORT_TRANSLATIONS = "ImportTranslations";
export const CREATE_TRANSLATIONS_KEY = "CreateTranslationsKey";
export const REMOVE_OR_UPDATE_TRANSLATION_KEY = "RemoveOrUpdateTranslationKey";
export const UPDATE_SYSTEM_DEFAULT = "UpdateSystemDefault";
export const CREATE_TRANSLATION_BY_KEY = "CreateTranslationByKey";
export const DELETE_TRANSLATION_BY_ID = "DeleteTranslationById";
export const UPDATE_TRANSLATION_BY_KEY = "UpdateTranslationByKey";

export const PARTNER = "Partner";
export const GET_PARTNERS_LIST_FILTERS = "GetPartnersListFilters";
export const GET_PARTNERS_LIST = "GetPartnersList";
export const GET_INSTANCES_FILTER = "GetInstancesFilter";
export const GET_PARTNER_STATUS_FILTER = "GetPartnerStatusFilter";
export const GET_PARTNERS_CURRENCIES = "GetPartnersCurrencies";
export const GET_CURRENCIES = "GetCurrencies";
export const ACTIVATE_PARTNER_BY_ID = "ActivatePartnerById";
export const DEACTIVATE_PARTNER_BY_ID = "DeactivatePartnerById";
export const ACTIVATE_PARTNERS_BY_FILTER = "ActivatePartnersByFilter";
export const DEACTIVATE_PARTNERS_BY_FILTER = "DeactivatePartnersByFilter";
export const GET_GAME_TYPES_FILTER = "GetGameTypesFilter";
export const EDIT_PARTNER = "EditPartner";
export const CREATE_PARTNER = "CreatePartner";
export const EDIT_PARTNER_INFO_AND_PRODUCTS = "EditPartnerInfoAndProducts";
export const UPDATE_PARTNER_QUICK_BET = "UpdatePartnerQuickBet";
export const ADD_PARTNER_QUICK_BET = "AddPartnerQuickBet";
export const DELETE_PARTNER_QUICK_BET = "DeletePartnerQuickBet";
export const CHECK_PARTNER_NAME_EXISTANCE = "CheckPartnerNameExistance";
export const GET_GAMES_LIST_FILTERS = "GetGamesListFilters";
export const GET_GAMES_LIST = "GetGamesList";
export const GET_QUICK_BETS_OF_GAME = "GetQuickBetsOfGame";
export const UPDATE_QUICK_BETS_OF_GAME_OF_PARTNER = "UpdateQuickBetsOfGameOfPartner";
export const DEACTIVATE_GAME_OF_PARTNER = "DeactivateGameOfPartner";
export const ACTIVATE_GAME_OF_PARTNER = "ActivateGameOfPartner";
export const ACTIVATE_GAMES_BY_FILTER = "ActivateGamesByFilter";
export const DEACTIVATE_GAMES_BY_FILTER = "DeactivateGamesByFilter";
export const UNDER_CONSTUCTION_GAMES_OF_PARTNERS = "UnderConstuctionGamesOfPartners";
export const EXIT_FROM_UNDER_CONSTUCTION_GAMES_OF_PARTNERS = "ExitFromUnderConstructionGamesOfPartners";
export const UNDER_CONSTUCTION_GAME_OF_PARTNER = "UnderConstuctionGameOfPartner";
export const EXIT_FROM_UNDER_CONSTUCTION_GAME_OF_PARTNER = "ExitFromUnderConstructionGameOfPartner";
export const CHECK_INSTANCE_NAME_EXISTANCE = "CheckInstanceNameExistence";
export const CREATE_2FA = "Create2FA";
export const GET_2FA = "Get2FA";
export const GET_2FA_FILTERS = "Get2FAFilters";
export const EDIT_2FA = "Edit2FA";
export const ACTIVE_INACTIVE_2FA = "ActiveInactive2FA";
export const SKINNING = "Skinning";
export const GET_SKINNINGS_FILTERS = "GetSkinningsListFilters";
export const GET_SKINNINGS_LIST = "GetSkinningsList";
export const CREATE_SKINNING = "CreateSkinning";
export const EDIT_SKINNING = "EditSkinning";
export const EDIT_SKINNING_STATUS = "EditSkinningStatus";
export const GET_SKINNING_BY_ID = "GetSkinningById";
export const GetBonusIntegrationTypesFilter = "GetBonusIntegrationTypesFilter";

export const DRAW = "Draw";
export const GET_TICKETS_LIST = "GetTicketsList";
export const GET_DRAWS = "GetDraws";
export const GET_TIKETS_LIST_FILTER = "GetTicketsListFilter";
export const GET_DRAWS_LIST_FILTER = "GetDrawsListFilter";
export const GET_DRAW_TICKETS = "GetDrawTickets";
export const GET_DETAILS_VIEW = "GetDetailsView";
export const GET_GAME_INFO = "GetGameInfo";
export const GET_PLAYER_LOG_FILTERS = "GetPlayerLogFilters";
export const GET_PLAYERS_LOG = "GetPlayerLogs";
export const RESEND_BET = "ResendBet";
export const RESEND_BETS = "ResendBets";
export const CHECK_BET_STATUS = "CheckBetStatus";

export const PERMISSION = "Permission";
export const ENABLE_OR_DISABLE_PERMISSION_GROUP = "EnableOrDisablePermissionGroup";
export const GET_PERMISSION_GROUP = "GetPermissionGroup";
export const GET_PERMISSION_GROUP_BY_ID = "GetPermissionGroupById";
export const CREATE_PERMISSION_GROUP = "CreatePermissionGroup";
export const UPDATE_PERMISSION_GROUP = "UpdatePermissionGroup";
export const GET_PERMISSION_GROUP_FILTERS = "GetPermissionGroupFilters";

export const BONUS = "Bonus";
export const GET_BONUS_LIST_FILTERS = "GetBonusListFilters";
export const GET_CREATE_BONUS_FILTERS = "GetCreateBonusFilters";
export const GET_PLAYERS_BONUS_LIST = "GetPlayerBonusList";
export const CANCEL_PLAYER_BONUS = "CancelPlayerBonus";
export const CANCEL_BONUS = "CancelBonus";
export const GET_BONUS_LIST = "GetBonusList";
export const UPDATE_BONUS = "UpdateBonus";
export const VIEW_DETAILS = "ViewDetails";
export const CREATE_BONUS = "CreateBonus";
export const GET_PLAYERS_BONUS_LIST_FILTERS = "GetPlayerBonusListFilters";
export const GET_BONUS_CAMPAIGN_LIST_FILTERS = "GetCampaignListFilters";
export const GET_CAMPAIGN_LIST = "GetCampaignList";
export const UPDATE_CAMPAIGN = "UpdateCampaign";
export const UPDATE_CAMPAIGN_STATUS = "UpdateCampaignStatus";
export const GET_CAMPAIGN_CREATE_FILTERS = "GetCreateCampaignFilters";
export const GRANT_PLAYER_BONUS = "CreateCampaign";
export const GET_BONUS_BY_ID = "GetBonusById";
export const GET_CAMPAIGN_PLAYERS = "GetCampaignPlayers";
export const GET_CAMPAIGN_BY_ID = "GetCampaignById";

export const ACCOUNT = "Account";
export const LOGIN = "Login";
export const FORGOT_PASSWORD = "ForgotPassword";
export const RESET_PASSWORD = "ResetPassword";
export const SET_PASSWORD = "SetPassword";
export const CONFIRM_EMAIL = "ConfirmEmail";
export const CHANGE_PASSWORD = "ChangePassword";
export const REGISTER_OPERATOR = "RegisterOperator";
export const SET_LAST_ACTIVITY = "SetLastActivity";
export const LOG_OUT = "LogOut";

export const OPERATOR = "Operator";
export const GET_FILTERS_FOR_OPERATOR = "GetFiltersForOperator";
export const GET_FILTERS_FOR_CREATE_OPERATOR = "GetFiltersForCreateOperator";
export const GET_OPERATOR_LIST_BY_FILTER = "GetOperatorListByFilter";
export const VIEW_OPERATOR = "ViewOperator";
export const UPDATE_OPERATOR = "UpdateOperator";
export const RESET_QR = "ResetQR";
export const RESEND_EMAIL = "ResendEmail";

export const DASHBOARD = "Dashboard";
export const COMPARE_STATISTICS = "CompareStatistics";
export const ACTIVE_INACTIVE_USER = "ActivInactiveUser";
export const GET_DASHBOARD_FILTER_FOR_TABLE = "GetDashboardFilterForTable";
export const GET_DASHBOARD_FILTER_FOR_CHART = "GetDashboardFilterForChart";
export const GET_GAME_REPORT_WITH_PARTNERS = "GetGameReportWithPartners";
export const GET_TOP_GAMES_PIE_CHART = "GetTopGamesByGGRBetAmountBetAndPlayersCount";
export const GET_TOP_PARTNERS_PIE_CHART = "GetTopPartnersByGGRBetAmountBetAndPlayersCount";
export const GET_BAR_CHART = "GetBetAmountGGRBetAndPlayersCount";

export const LOGGING = "Logging";
export const GET_USER_LOG_FILTERS = "GetUserLogFilters";
export const GET_USER_LOGS = "GetUserLogs";
export const GET_LOG_INFO = "GetLogInfo";
export const VERIFY = "Verify";

export const SYSTEM_SETTINGS = "SystemSettings";
export const GET_SYSTEM_SETTINGS = "GetSystemSettings";
export const UPDATE_SYSTEM_SETTINGS = "UpdateSystemSettings";

export const CURRENCY = "Currency";
export const GET_CURRENCIES_RATES_FILTERS = "GetCurrenciesRatesFilters";
export const GET_CURRENCIES_RATES_BY_FILTER = "GetCurrenciesRatesByFilter";
export const GET_PARTNER_CURRENCIES_RATES_FILTERS = "GetPartnerCurrenciesRatesFilters";
export const EDIT_CURRENCY_RATE_BY_IDS = "EditCurrencyRateByIds";
export const EDIT_CURRENCY_RATE_BY_CURRENCY_ID_AND_DATE_RANGE = "EditCurrencyRateByCurrencyIdAndDateRange";
export const GET_ALL_CURRENCY = "GetAllCurrency";
export const GET_CURRENCIES_WITH_PRECISION = "GetCurrenciesWithPrecision";
export const UPDATE_CURRENCIES_PRECISION = "UpdateCurrenciesPrecision";
export const GET_CURRENCY_AND_CONFIG = "GetCurrencyAndCurrencyConfig";
export const GET_CURRENCY_AND_CONFIG_LIST = "GetCurrencyAndCurrencyConfigList";
export const CREATE_CURRENCY_AND_CURRENCY_CONFIG = "CreateCurrencyAndCurrencyConfig";
export const UPDATE_CURRENCY_AND_CURRENCY_CONFIG = "UpdateCurrencyAndCurrencyConfig";

export const LEADERBOARD = "Leaderboard";
export const GET_LEADERBOARDS = "GetLeaderboards";
export const GET_LEADERBOARD_PLAYERS = "GetLeaderboardPlayers";
export const GET_LEADERBOARDS_LIST_FILTERS = "GetLeaderboardsListFilters";
export const GET_CREATE_EDIT_FILTERS = "GetCreateEditFilters";
export const GET_LEADERBOARD_BY_ID = "GetLeaderboardById";
export const GET_LEADERBOARD_PLAYERS_FILTERS = "GetLeaderboardPlayersListFilters";
export const CREATE_LEADERBOARD = "CreateLeaderboard";
export const EDIT_LEADERBOARD = "EditLeaderboard";
export const DEACTIVATE = "Deactivate";
export const ACTIVATE = "Activate";
export const EXPORT_LEADERBOARD_PLAYERS = "ExportLeaderboardPlayers";
export const EXPORT_LEADERBOARD_REPORT = "ExportLeaderboardReport";
export const GET_LEADERBOARD_REPORT_FILTERS = "GetLeaderboardReportFilters";
export const GET_LEADERBOARD_REPORT = "GetLeaderboardReport";

export const CASHBACK = "CashBack";
export const GET_CASHBACK_FILTERS = "GetCashBackFilters";
export const GET_CASHBACK_PLAYERS_FILTERS = "GetCashBackPlayersFilters";
export const GET_CASHBACK = "GetCashBack";
export const ACTIVE_INACTIVE_CASHBACK = "ActiveInactiveCashBack";
export const CREATE_CASHBACK = "CreateCashBack";
export const EDIT_CASHBACK = "EditCashBack";
export const GET_CASHBACK_PLAYERS_LIST = "GetCashBackPlayersList";
export const EXPORT_CASHBACK_PLAYERS_LIST = "ExportCashBackPlayersList";

export const REPORTING = "Reporting";
export const GET_FREQUENT_WINS = "GetFrequentWins";
export const GET_FILTERS_FOR_FREQUENT_WINS = "GetFiltersForFrequentWins";
export const GET_FILTERS_FOR_BETS_REPORT = "GetFiltersForBetsReport";
export const GET_BETS_REPORT = "GetBetsReport";
export const EXPORT_FREQUENT_WINS_BY_PLAYER = "ExportFrequentWinsByPlayer";
export const GET_FILTERS_FOR_INVOICE = "GetFiltersForInvoice";
export const GET_INVOICE_REPORT = "GetInvoiceReport";
export const EXPORT_INVOICE_REPORT = "ExportInvoiceReport";
export const EXPORT_BET_REPORT = "ExportBetReport";

export const CHAT = "Chat";
export const GET_MESSAGES_LIST_FILTERS = "GetMessagesListFilters";
export const GET_MESSAGES_LIST = "GetMessagesList";
export const GET_CHAT_PLAYERS_LIST_FILTERS = "GetChatPlayersListFilters";
export const GET_CHAT_PLAYERS_LIST = "GetChatPlayersList";
export const RESET_PLAYER_NICKNAME = "ResetPlayerNickName";
export const BLOCK_PLAYER = "BlockPlayer";
export const MAKE_INFLUENCER = "MakeInfluencer";
export const GET_CHATS_LIST_FILTERS = "GetChatsListFilters";
export const GET_CHATS_LIST = "GetChatsList";
export const EDIT_CHAT_STATUS = "EditChatStatus";
export const CREATE_CHAT = "CreateChat";
export const EDIT_CHAT = "EditChat";
export const GET_CHAT_BY_ID = "GetChatById";
export const GET_PROFANITIES_LIST = "GetProfanitiesList";
export const UPDATE_PROFANITY = "UpdateProfanity";
export const ADD_PROFANITY = "AddProfanity";
export const IMPORT_PROFANITIES = "ImportProfanities";
export const GET_STICKERS_LIST_FILTERS = "GetStickersListFilters";
export const GET_STICKERS_LIST = "GetStickersList";
export const UPDATE_STICKERS = "UpdateStickers";
export const UPDATE_AVATARS = "UpdateAvatars";
export const GET_AVATARS_LIST_FILTERS = "GetAvatarsListFilters";
export const GET_AVATARS_LIST = "GetAvatarsList";
export const UPDATE_MESSAGE_STATE = "UpdateMessageState";

export const RISK_MANAGEMENT = "riskManagement";
// RISK_MANAGEMENT -> CAMPAIGN PAGE
export const CAMPAIGN = "Campaign";
export const CAMPAIGN_GET_LIST = "CampaignGetList";
export const GET_CAMPAIGNS_LIST_FILTERS = "GetCampaignsListFilters";
export const CAMPAIGN_ACTIVATE = "CampaignActivate";
export const CAMPAIGN_DEACTIVATE = "CampaignDeactivate";
export const GET_CAMPAIGN_TEMPLATE_FILTER = "getCampaignTemplateFilters";

// RISK_MANAGEMENT -> LISTS
export const RECIPIENT = "Recipient";
export const RECIPIENT_LIST = "RecipientList";
export const RECIPIENT_GET_ALL = "RecipientGetAll";
export const GET_ALL = "RecipientListsGetAll";
export const RISKY_PLAYERS_LIST = "RiskyPLayersList";
export const READ_ALL = "ReadAll";
export const FILTERS = "Filters";

// RISK_MANAGEMENT -> ALERTS
export const ALERT = "Alert";
export const GET_ANALYTICS_FILTERS = "GetRiskyAlertsFilters";
export const GET_ALERT_EMAILS_FILTERS = "GetRiskyAlertsEmailsFilters";
export const GET_ANALYTICS = "GetAnalytics";

// RISK_MANAGEMENT -> MAX_WINNERS
export const MAX_WINNER = "MaxWinner";
export const GET_MAX_WINNERS_FILTERS = "GetMaxWinnersListFilters";
export const GET_MAX_WINNERS_LIST = "GetMaxWinnersList";

// PLAY LINK
export const PARTNER_CONFIGURATION = "PartnerConfiguration";
export const GET_PARTNER_CONFIGURATION_LIST_FILTERS = "GetPartnerConfigurationListFilters";
export const GET_PARTNER_CONFIGURATION_CREATE_FILTERS = "GetPartnerConfigurationCreateFilters";
export const PARTNER_CONFIGURATION_CREATE = "PartnerConfigurationCreate";
export const PARTNER_CONFIGURATION_GET_LIST = "PartnerConfigurationGetList";

export const LICENSE = "License";
export const GET_LICENSES = "GetLicenses";

// STORAGE_MANAGEMENT
export const STORAGE = "Storage";
export const GET_FILES = "GetFiles";
export const GET_FILE = "GetFile";
export const CREATE_FOLDER = "CreateFolder";
export const DELETE_FOLDER = "DeleteFolder";
export const DELETE_FILE = "DeleteFile";
export const UPLOAD_FILE = "UploadFile";
export const IMPORT_FILES = "ImportFiles";
export const DOWNLOAD_FILE = "DownloadFile";
export const EXPORT_FILES = "ExportFiles";
export const RENAME_FILE = "RenameFile";
export const RENAME_FOLDER = "RenameFolder";
export const REPLACE_FILES = "ReplaceFiles";

// RTPMonitoring
export const RTPMONITORING = "RTPMonitoring";
export const GetAllFilters = "GetAllFilters";
export const GetRTPMontoringList = "GetRTPMontoringList";
export const GetRTPThreshold = "GetRTPThreshold";
export const UpdateGameRTPStatus = "UpdateGameRTPStatus";
export const UpdateGameRTPById = "UpdateGameRTPById";
export const GetChartTopPlayers = "GetChartTopPlayers";
export const GetGameRtpCharts = "GetGameRtpCharts";
export const GetNotifications = "GetNotifications";
export const UpdateNotificationById = "UpdateNotificationById";
export const ExportNotifications = "ExportNotifications";
export const AddEmailReceiver = "AddEmailReceiver";
export const UpdateEmailReceiver = "UpdateEmailReceiver";
export const AddEmailReceiversGroup = "AddEmailReceiversGroup";
export const UpdateEmailReceiversGroup = "UpdateEmailReceiversGroup";
export const ApplyEmailReceiversGroupsToAll = "ApplyEmailReceiversGroupsToAll";
export const GetEmailReceivers = "GetEmailReceivers";
export const GetEmailReceiversGroups = "GetEmailReceiversGroups";
export const DeleteEmailReceiverGroup = "DeleteEmailReceiverGroup";
export const DeleteEmailReceiver = "DeleteEmailReceiver";
export const GetEmailReceiversGroupsById = "GetEmailReceiversGroupsById";
export const GetAllRtps = "GetAllRtps";
